import React, { useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { parseAndFormatDateTime } from 'utils/date';

const useStyles = makeStyles((theme) => ({
  comment: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    padding: "14px",
    borderRadius: "5px",
    margin: 8,
    maxWidth: 350,
    whiteSpace: "break-spaces",
  },
}));

function CommentsView({ comments }) {
  const classes = useStyles();
  const [pcomments, setPcomments] = useState();

  useEffect(()=>{
    const filtered = comments.filter(com=>{
      if (typeof value === 'string'){
        com.data = JSON.parse(com.data);
      }
      return {...com}
    });
    setPcomments(filtered);
  }, [comments]);

  return (
    <>
      {pcomments && pcomments.length > 0 && (
        <TableRow>
          <TableCell component="th" scope="row" style={{ textAlign: "right" }}>
            Comments:
          </TableCell>
          <TableCell colSpan={5}>
            {pcomments.map((comment) => (
              <div key={"c" + comment.id} className={classes.comment}>
                <div><b>{comment?.editor_name ? comment?.editor_name : (comment.sender_id ? comment.sender_id : '')}</b>{comment.data?.filename ? <small style={{ color: "#b0b0b0", marginLeft: 6 }}>{comment.data.filename}</small> : ''} {comment.data?.uploaded_at ? <small style={{ color: "#b0b0b0", marginLeft: 6 }}>-{ parseAndFormatDateTime(comment.data.uploaded_at)}</small> : ''}</div>
               
                <br />
                <div style={{ marginTop: 4 }}>
                  {comment.comment}
                  <small style={{ float: "right", color: "#b0b0b0" }}>{parseAndFormatDateTime(comment.created_at)}</small>
                </div>
              </div>
            ))}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default CommentsView;