export const getMetaValue = (teamMeta, meta_key) => {
   if (!teamMeta) return null;
      const tMeta = teamMeta.find(meta=>meta.key === meta_key);
      if (tMeta) {
         // console.log('tMeta', tMeta)
         return tMeta.value;
      }
      return null
}

// export const rgbaToHex = (color) => {
//    if (!color || color.substr(0, 1) === '#') {
//        return color;
//    }
   
//     const rgbaValues = color.match(/(\d+(\.\d+)?)/g);
    
//     // Extract the alpha value
//     const alpha = parseFloat(rgbaValues[3]);
    
//     // Convert RGBA to HEX
//     let hexColor = '#' + (
//         ((1 << 24) + // Add 1 to shift alpha to 8 bits
//         (parseInt(rgbaValues[0]) << 16) + // Red
//         (parseInt(rgbaValues[1]) << 8) + // Green
//         parseInt(rgbaValues[2])) // Blue
//         .toString(16) // Convert to hexadecimal
//         .slice(1) // Remove leading '1' added above
//     );

//     // Append alpha value if it's not fully opaque
//     if (alpha !== 1) {
//         const alphaHex = Math.round(alpha * 255).toString(16);
//         hexColor += alphaHex.length === 1 ? '0' + alphaHex : alphaHex;
//     }
//     return hexColor;
// }

export function rgbaToHex(rgba) {
    if (!rgba) return null;
    if (rgba?.includes("rgb")) {

        let [r, g, b, a] = rgba.match(/\d+(\.\d+)?/g).map(num => parseFloat(num));

        r = Math.round((1 - a) * 255 + r * a);
        g = Math.round((1 - a) * 255 + g * a);
        b = Math.round((1 - a) * 255 + b * a);

        return "#" + r.toString(16).padStart(2, '0')
            + g.toString(16).padStart(2, '0')
            + b.toString(16).padStart(2, '0');
    }
    return rgba;
}
